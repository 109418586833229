import { useStaticQuery, graphql } from 'gatsby';

const useLogo = () => {
  const data = useStaticQuery(graphql`
    {
      allLogoJson {
        nodes {
          logo
        }
      }
    }
  `);

  return data.allLogoJson.nodes[0]['logo'];
};

export default useLogo;
