import { useStaticQuery, graphql } from 'gatsby';

const useFreshLogo = () => {
  const data = useStaticQuery(graphql`
    query  {
      file(relativePath: {eq: "fresh-footer-logo.png"}) {
        childImageSharp {
          fluid (quality: 100){
            aspectRatio
            base64
            sizes
            src
            srcSet
          }
        }
      }
    }
  `);

  return data.file.childImageSharp.fluid;
};

export default useFreshLogo;
