import React, {useState} from "react"
import {Link} from "gatsby"
import MenuItems from "./menu";


//hooks
import useLogo from "../hooks/use-logo";
import useHeader from "../hooks/use-header";

const Header = () => {

  let bodySelector = typeof document !== "undefined" ? document.body : ''

  const [isVisible, setIsVisible] = useState(false);

  const toggleVisible = (e) => {
    e.preventDefault();
    setIsVisible(!isVisible);

    if(!isVisible){
      bodySelector.classList.add('open-menu')

    } else {
      bodySelector.classList.remove('open-menu')
    }

  };

  /*Debounce function to add remove class in header menu*/
  function debounce(func, duration) {
    let timeout
    return function(...args) {
      const effect = () => {
        timeout = null
        return func.apply(this, args)
      }
      clearTimeout(timeout)
      timeout = setTimeout(effect, duration)
    }
  }

  const addMobileResponsiveClass = () => {
    var windowSizeSelector = typeof window !== "undefined" ? window.innerWidth : '';
    var headerMenuSelector = typeof document !== "undefined" ? document.getElementsByClassName('header-menu') : '';
    if(headerMenuSelector.length > 0){
      windowSizeSelector <= 766 ? headerMenuSelector[0].classList.add('mobile-version') : headerMenuSelector[0].classList.remove('mobile-version');
    }
    return;
  }
  addMobileResponsiveClass();
  if(typeof window !== "undefined"){
    window.addEventListener('resize', debounce(function() {
      addMobileResponsiveClass()
    }, 500));
  }


  return (
    <header className="header">
        <div className="header__container">
          <div className="row justify-content-between align-items-center">
            <div className="col-6">
              <div className="header___logo-wrapper">
                <Link to={`/`}>
                  <img src={ useLogo() } alt={`BalanceTech Logo`} className="header__logo img-fluid" />
                </Link>
              </div>
            </div>
            <div className="col-6">

              <nav id={`primary-menu`} className={"primary-menu " + (isVisible ? 'open-menu' : '')}>
                {/* Disabling eslint because the menu only appears in small screen devices  */}
                <span id="hamburger-menu" title={`menu`} onClick={toggleVisible} role="button"  tabIndex="0"//eslint-disable-line jsx-a11y/click-events-have-key-events
                   >
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
                <MenuItems iteamClassName={"header-menu"} menuList={useHeader()}/>
              </nav>
            </div>
          </div>
        </div>
    </header>
  )
}

export default Header
