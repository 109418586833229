import { useStaticQuery, graphql } from 'gatsby';

const useFooter = () => {
  const data = useStaticQuery(graphql`
    {
      allFooterJson {
        nodes {
          url
          title
          target
        }
      }
    }
  `);

  return data.allFooterJson.nodes;
};

export default useFooter;
