import React from "react"
import MenuItems from "./menu";
import Img from "gatsby-image";

//hooks
import useFooter from "../hooks/use-footer";
import useFreshLogo from "../hooks/use-freshlogo";

const Footer = () => {
  return (
    <footer className="footer">
        <div className="footer__container">
          <div className="row justify-content-between ">
            <div className="col-12 order-1 order-md-0 col-md-6">
              <div className="footer__image">
                <a href="https://freshconsulting.com" target="_blank" rel="noopener noreferrer">
                <Img objectFit="contain"
                     className="img-fluid" fluid={useFreshLogo()} alt={`Fresh Footer Logo`}/>
                </a>
              </div>
            </div>
            <div className="col-12 order-0 order-md-1 col-md-6">
              <MenuItems iteamClassName="footer-menu" menuList={useFooter()}/>
            </div>
          </div>
        </div>
    </footer>
  )
}

export default Footer
