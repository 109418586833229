import { useStaticQuery, graphql } from 'gatsby';

const useHeader = () => {
  const data = useStaticQuery(graphql`
    {
      allHeaderJson {
        nodes {
          url
          title
          target
        }
      }
    }
  `);

  return data.allHeaderJson.nodes;
};

export default useHeader;
